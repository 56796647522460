<template>
  <div>
    <WsCreate
      :modelName="modelName"
      :label="label"
      :fields="fields"
      v-model="updateData"
      :emitInput="true"
      v-bind="pageSetting"
      :extendPostData="extendPostData"
    >
    </WsCreate>
  </div>
</template>

<script>
import model from "@/__vue2stone_cms/models/featured_class";
export default {
  metaInfo() {
    return {
      title: `新增${this.label}`,
    };
  },
  data: () => ({
    extendPostData: {
      type: "current",
    },
    updateData: null,
    modelName: model.modelName,
    label: model.label,
    fields: model.fields,
    pageSetting: {
      primary: [
        {
          type: "stateCard",
          floors: [
            {
              fields: ["name", "order_type", "icon"],
            },
          ],
        },
      ],
      secondary: [
        {
          type: "stateCard",
          floors: [
            {
              title: "設定",
              fields: ["sq"],
            },
          ],
        },
      ],
    },
  }),
};
</script>

<style>
</style>